:root {
  --deepseablue: #003D6D;
  --oceanteal: #006882;
  --darkslategrey: #252525;
  --whitesmoke: #F6FAFB;
  --charcoal: #484848;
  --orange: #FF9800;

  --title: var(--deepseablue);
  --subtitle: var(--darkslategrey);
  --text: var(--charcoal);

  --accent: var(--deepseablue);
  --accent-hover: var(--deepseablue-rgba-hover);
  --accent-secondary: var(--oceanteal);
  --accent-secondary-hover: var(--oceanteal-rgba-hover);
  --background-color: var(--whitesmoke);
  --warning: var(--orange);

  --border-radius: .8rem;

  --viewport-width: 100vw;
  --viewport-height: 100vh;
}
