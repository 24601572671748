@import "./colors.scss";
@import "./fonts.scss";

html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,body {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--background-color);
  color: var(--text);
  font-family: "Barlow Regular";
  font-size: 1.6rem;
  margin: 0 auto;
}

h1 {
  font-family: "Barlow Bold";
  color: var(--title);
  line-height: 120%;
  margin: 0 0 2rem 0;
  font-size: 2.8rem;
  text-align: center;
}

h2 {
  font-family: "Barlow Regular";
  color: var(--subtitle);
  margin: 0 0 1.5rem 0;
  line-height: 120%;
  font-size: 2.1rem;
  text-align: center;
  span {color: var(--accent-secondary)};
}

p {
  font-size: 1.8rem;
  font-family: "Barlow Regular";
  color: var(-text);
  span {color: var(--accent-secondary)};
  line-height: 150%;
}

hr {
  display: block;
  width: 100%;
  height: 0.3rem;
  background-color: #ececec;
  border: none;
}

ul {
  padding: 0;

  a {
  text-decoration: none;
  }
}

.leadText {
  font-size: 2rem;
  font-family: "Barlow Regular";
  text-align: center;
  color: var(-text);

  span {color: var(--accent-secondary)}
}

main {
  width: 100%;
  min-height: 100vh;
  padding: 1.2rem;
  margin-top: 6.6rem;
  z-index: 0;
}

#root {
  display: grid;
  grid-template-columns: 1fr;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, var(--accent-secondary) 50%),
  linear-gradient(135deg, var(--accent-secondary) 50%, transparent 50%),
  radial-gradient(#D9E7EB 70%, transparent 72%);
  background-position: calc(100% - 2rem) calc(1em + 4px), calc(100% - 1.5rem) calc(1em + 4px), calc(100% - 0.7rem) 1rem;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, var(--accent-secondary) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--accent-secondary) 50%),
    radial-gradient(#D9E7EB 70%, transparent 72%);
    background-position: calc(100% - 14px) 1.1em, calc(100% - 19px) 1.1em, calc(100% - .6rem) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 4rem;
    margin: 0 0 3rem 0;
  }

  h2 {
    font-size: 2.4rem;
    margin: 0 0 2rem 0;
  }

  p {
    font-size: 1.8rem;
  }

  .leadText {
    font-size: 2.4rem;
  }
}

@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 4.8rem;
    margin: 0 0 3rem 0;
  }

  h2 {
    font-size: 2.6rem;
    margin: 0 0 2.5rem 0;
  }

  p {
    font-size: 1.9rem;
  }

  .leadText {
    font-size: 2.6rem;
  }
}

@media only screen and (min-width: 1440px) {
  h1 {
    font-size: 5.2rem;
    margin: 0 0 3rem 0;
  }

  h2 {
    font-size: 2.7rem;
    margin: 0 0 3rem 0;
  }

  p {
    font-size: 1.9rem;
  }

  .leadText {
    font-size: 2.4rem;
  }
}
