@font-face {
  font-family: "Barlow Light";
  src: url("../../assets/fonts/Barlow-Light.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Light.woff") format("woff");
}

@font-face {
  font-family: "Barlow Light Italic";
  src: url("../../assets/fonts/Barlow-Light-Italic.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Light-Italic.woff") format("woff");
}

@font-face {
  font-family: "Barlow Regular";
  src: url("../../assets/fonts/Barlow-Regular.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Regular.woff") format("woff");
}

@font-face {
  font-family: "Barlow Regular Italic";
  src: url("../../assets/fonts/Barlow-Regular-Italic.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Regular-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Barlow Medium';  
  src: url("../../assets/fonts/Barlow-Medium.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Medium.woff") format("woff");
}

@font-face {
  font-family: "Barlow SemiBold";
  src: url("../../assets/fonts/Barlow-SemiBold.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Barlow SemiBold Italic";
  src: url("../../assets/fonts/Barlow-SemiBold-Italic.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-SemiBold-Italic.woff") format("woff");
}


@font-face {
  font-family: "Barlow Bold";
  src: url("../../assets/fonts/Barlow-Bold.woff2") format("woff2"),
  url("../../assets/fonts/Barlow-Bold.woff") format("woff");
}
